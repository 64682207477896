/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./assets/fonts/fonts.scss";
:root[mode="ios"] body,
:root[mode="md"] body {
  --ion-font-family: "Roboto" !important;
}
$font-primary: "Heebo", sans-serif !important;
$font-secondary: "Roboto", sans-serif !important;
// Everything in body so that ionic elements doesnt overiride global css
body {
  .one3d-sidePanelModal {
    flex: 0 0 350px !important;
    --max-width: 350px !important;
    justify-content: flex-start;
    --height: 100%;
    align-items: flex-end;
    --border-radius: 0px;
    --backdrop-opacity: 0;
  }
  .ff-primary {
    $font-primary: "Heebo";
  }
  .ff-secondary {
    $font-primary: "Roboto";
  }
  .one3d-sidePanelModal-summary {
    flex: 0 0 350px !important;
    max-width: 350px !important;
    justify-content: flex-start;
    --height: 100%;
    align-items: flex-end;
    --border-radius: 0px;
    --backdrop-opacity: 0;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .one3d-accessoriesModal {
    flex: 0 0 350px !important;
    max-width: 350px !important;
    justify-content: flex-start;
    --height: 100%;
    align-items: flex-end;
    --border-radius: 0px;
    --backdrop-opacity: 0;
  }
  .one-3D-tutorialModal {
    --max-width: 50%;
  }
  .one-3d-flex {
    display: flex;
  }
  input {
    outline: none;
  }
  .one-3d-full-page-modal {
    --height: 100%;
    --width: 100%;
    --border-radius: 0px;
  }
  .one-3d-fullHeight {
    height: 100%;
    position: relative;
  }
  .one3d-accordion-item .ion-accordion-toggle-icon {
    color: #487f70;
  }
  .one3d-full-width {
    width: 100%;
  }
  button {
    background: none;
  }
  .one3d-top-padding-0 {
    padding-top: 0;
  }
  .one3d-bottom-padding-0 {
    padding-bottom: 0;
  }
  .one3d-placeAtBottom {
    bottom: 0;
    position: absolute;
  }
  .one3d-extra-bold {
    font-weight: 800;
  }
  .one3d-color-black {
    color: var(--ion-color-dark);
  }
  .one3d-delete-icon {
    color: #f63d3d;
  }
  .one3d-uppercase {
    text-transform: uppercase;
  }
  // Center horizontally and vertically
  .one3d-center-xy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  // LHS Panel - Common for visualizer and configurator
  .one3d-lhs-panel-header {
    background-color: var(--ion-color-dark);
    /*padding: 8px 14px;*/
    padding: 0;
    z-index: 0;
  }
  .one3d-brand-logo {
    padding: 4px 14px;
    opacity: 0;
  }
  .one3d-postcode-title {
    padding: 8px 16px;
  }
  .one3d-lhs-panel-title {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: var(--ion-color-dark-contrast);
  }
  // LHS Panel - Accordions
  .one3d-accordion-item {
    position: relative;
  }
  .one3d-accordion-item::before {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 4px;
    z-index: 2;
    background-color: rgba(209, 209, 209, 1);
  }
  .one3d-accordion-item-selected::before {
    background-color: var(--ion-color-primary);
  }
  .one3d-accordion-label,
  .one3d-accordion-variant-label {
    font-size: 1rem;
    font-weight: 800;
  }
  .one3d-accordion-variant-selected {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 500;
    color: #636363;
  }
  .one3d-accordion-sub-item {
    max-height: 38px;
  }
  .one3d-avl-item ion-checkbox::part(container) {
    border: 1px solid #3e3e3e;
  }
  .one3d-avl-item-mobile ion-checkbox::part(checkbox-checked) {
    background: var(--ion-color-primary);
  }
  .one3d-acc-wrapper
    ion-accordion.accordion-expanding
    > [slot="header"]
    .ion-accordion-toggle-icon,
  .one3d-acc-wrapper
    ion-accordion.accordion-expanded
    > [slot="header"]
    .ion-accordion-toggle-icon {
    transform: rotate(90deg);
  }
  .one3d-avl-wrapper
    ion-accordion.one3d-acc-variants
    > [slot="header"]
    .ion-accordion-toggle-icon,
  .one3d-accordion-sub-wrapper
    ion-accordion
    > [slot="header"]
    .ion-accordion-toggle-icon {
    background-image: url("assets/img/icons/one3d-info-icon.svg");
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .one3d-accordion-sub-wrapper
    ion-accordion
    > [slot="header"]
    .ion-accordion-toggle-icon {
    background-image: url("assets/img/icons/one3d-plus-icon.svg");
    position: absolute;
    right: 20px;
  }
  .one3d-avl-wrapper
    ion-accordion.accordion-expanding
    > [slot="header"]
    .ion-accordion-toggle-icon,
  .one3d-avl-wrapper
    ion-accordion.accordion-expanded
    > [slot="header"]
    .ion-accordion-toggle-icon,
  .one3d-accordion-sub-wrapper
    ion-accordion.accordion-expanding
    > [slot="header"]
    .ion-accordion-toggle-icon,
  .one3d-accordion-sub-wrapper
    ion-accordion.accordion-expanded
    > [slot="header"]
    .ion-accordion-toggle-icon {
    background-image: url("assets/img/icons/one3d-minus-icon.svg");
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    transform: none;
    transition: none;
  }
  // Border-bottom
  .one3d-custom-border-bottom {
    position: relative;
  }
  .one3d-ef-feature-title::after,
  .one3d-accordion-item::after,
  .one3d-avf-wrapper::after,
  .one3d-avl-item::after,
  .one3d-sub-acc-top::after,
  .one3d-summary-total::after,
  .one3d-custom-border-bottom::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    z-index: 2;
    background: linear-gradient(
      90deg,
      rgba(255, 249, 249, 0.6) 0%,
      #656366 11.25%,
      #656366 87.98%,
      #fffdfd 98.21%
    );
    opacity: 0.3;
    left: 0;
  }
  .one3d-summary-wrapper .one3d-custom-border-bottom::after {
    z-index: 0;
  }
  .one3d-avf-wrapper::after {
    bottom: -15px;
  }
  .one3d-sub-acc-top::after {
    bottom: -6px;
  }
  // LHS Bottom
  .one3d-lhs-btn,
  .one3d-lhs-config-btn {
    width: 100%;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.375rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    letter-spacing: normal;
  }
  .one3d-lhs-config-btn {
    font-size: 12px;
  }
  .one3d-lhs-btn span,
  .one3d-lhs-config-btn span {
    margin-left: 10px;
    font-size: 14px;
  }
  .one3d-lhs-btn span ion-icon,
  .one3d-lhs-config-btn span ion-icon {
    position: relative;
    top: 1px;
  }
  .one3d-lhs-bottom-details {
    margin-right: 12px;
  }
  .one3d-lhs-bottom-wrapper,
  .one3d-lhs-config-bottom-wrapper {
    background-color: var(--ion-color-dark-contrast);
  }
  .one3d-lhs-config-bottom-wrapper {
    width: 100%;
  }
  .one3d-lhs-bottom-selected,
  .one3d-lhs-config-bottom-selected {
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
  }
  .one3d-lhs-bottom-title,
  .one3d-lhs-config-bottom-title {
    /*font-size: 0.75rem;*/
    font-size: 14px;
    font-weight: 700;
    line-height: 1.125rem;
    margin: 0;
    color: var(--ion-color-dark);
  }
  .one3d-lhs-bottom-price,
  .one3d-lhs-config-bottom-price {
    font-size: 0.75rem;
    font-weight: 800;
    margin: 0;
    color: var(--ion-color-dark);
  }
  .one3d-lhs-bottom-price span,
  .one3d-lhs-config-bottom-price span {
    font-size: 0.625rem;
    font-family: 400;
    margin-left: 6px;
    color: #636363;
  }
  .one3d-lhs-bottom-btn button,
  .one3d-lhs-config-bottom-btn button {
    border: 1px solid #000000;
    border-radius: 2px;
    padding: 8px;
    font-size: 0.75rem;
    font-weight: 500;
    color: black;
  }
  .one3d-lhs-config-bottom-btn button span {
    font-size: 0.875rem;
    color: black;
  }
  .one3d-lhs-test-btn,
  .one3d-lhs-config-left-btn {
    --background: #636363;
    color: #ffffff;
    --background-activated: none;
    height: 37px;
  }
  .one3d-lhs-book-btn,
  .one3d-lhs-config-right-btn {
    color: #fff;
    height: 37px;
    --background: var(--ion-color-primary);
  }
  .one3d-lhs-config-selected-mobile {
    display: block;
  }
  .one3d-overflow-hidden {
    --overflow: hidden;
  }
  .one3d-scrollable-wrapper {
    max-height: calc(100vh - 160px);
    overflow: auto;
  }
  .one3d-scrollable-wrapper-custom {
    overflow: auto;
  }
  .one3d-scrollable-wrapper-custom-remove-bottom-space {
    max-height: calc(100vh - 124px) !important;
  }
  /* width */
  .one3d-scrollable-wrapper::-webkit-scrollbar,
  .one3d-scrollable-wrapper-custom::-webkit-scrollbar {
    width: 4px;
  }
  .one3d-scrollable-wrapper-custom.one3d-trade-in-calculator-wrap::-webkit-scrollbar {
    width: 0;
  }
  /* Track */
  .one3d-scrollable-wrapper::-webkit-scrollbar-track,
  .one3d-scrollable-wrapper-custom::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 20px;
  }
  /* Handle */
  .one3d-scrollable-wrapper::-webkit-scrollbar-thumb,
  .one3d-scrollable-wrapper-custom::-webkit-scrollbar-thumb {
    background-color: var(--ion-color-primary);
    border-radius: 20px;
  }
  .one3d-scrollable-visualizer-wrapper {
    max-height: calc(95vh - 110px);
  }
  // Configurator LHS panel content header
  .one3d-content-header-title {
    font-size: 1.25rem;
    font-weight: 800;
    margin: 0;
  }
  .one3d-content-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 14px;
    position: relative;
    align-items: center;
  }
  // Bottom dropdown for seat rows
  .one3d-seating-rows-wrapper {
    background: var(--ion-color-dark-contrast);
    border-radius: 2px;
  }
  .one3d-visualizer-seating-rows {
    position: absolute;
    top: 6px;
    left: -194px;
  }
  .one3d-seating-rows {
    font-size: 0.75rem;
    font-weight: 800;
    padding: 0.75rem;
    min-height: auto;
  }
  .one3d-seating-rows .select-highlight {
    display: none;
  }
  .one3d-seating-rows option {
    border: 1px solid rgba(191, 191, 191, 0.4);
  }
  .one3d-seating-rows ion-select-option {
    --color: var(--ion-color-dark);
  }
  .one3d-accessories-nav {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .one3d-accessories-nav-title {
    width: 100%;
    font-weight: 800;
    color: #636363;
    font-size: 0.75rem;
    --padding-end: 4px;
    line-height: 1.25rem;
    --padding-start: 4px;
  }
  .one3d-accessories-nav-title-selected {
    color: var(--ion-color-dark);
  }
  .one3d-acc-remove-btn {
    padding: 6px 8px;
    color: #f63d3d;
    border: 1px solid #f63d3d;
    border-radius: 1px;
    //   display: none;
  }
  .one3d-acc {
    margin-bottom: 20px;
  }
  .one3d-acc-name {
    font-size: 0.875rem;
    font-weight: 800;
    color: #3a3a3a;
    margin-bottom: 8px;
  }
  .one3d-acc-card {
    display: flex;
    margin-bottom: 20px;
  }
  .one3d-acc-card-new {
    width: 100%;
    flex-wrap: wrap;
    cursor: initial;
    justify-content: space-around;
  }
  .one3d-acc-img-section {
    position: relative;
  }
  .one3d-acc-img-section,
  .one3d-sub-acc-img-section {
    // padding-right: 0.625rem;
    // flex: 0 0 40%;
    // max-height: 70px;
    padding-right: 0.625rem;
    flex: 0 0 40%;
    overflow: hidden;
    max-height: 70px;
    cursor: pointer;
  }
  .one3d-3d-overlay {
    position: absolute;
    bottom: 0;
    right: 10px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 120%
    );
    width: 90%;
  }
  .one3d-3d-icon {
    margin: 0 2px 4px 2px;
    height: 22px;
    float: right;
  }
  .one3d-acc-img {
    border-radius: 2px;
  }
  .one3d-acc-content-title,
  .one3d-sub-acc-content-title {
    font-size: 0.75rem;
    font-weight: 400;
    color: #3a3a3a;
    padding-bottom: 8px;
    margin: 0;
  }
  .one3d-acc-content-price {
    font-size: 0.75rem;
    font-weight: 800;
    color: var(--ion-color-dark);
    line-height: 1.125rem;
    margin: 0;
    // padding-bottom: 10px;
  }
  .one3d-acc-content-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .one3d-acc-view-details-btn {
    font-size: 0.75rem;
    font-weight: 400;
    text-decoration: underline;
    color: var(--ion-color-dark);
  }
  .one3d-acc-add-btn {
    font-size: 0.75rem;
    font-weight: 600;
    background-color: var(--ion-color-primary);
    line-height: 1.125rem;
    padding: 4px 12px;
    border-radius: 2px;
    color: #fff;
  }
  .one3d-acc-img,
  .one3d-sub-acc-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    max-width: none;
  }
  .one3d-acc-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 60%;
    min-height: 70px;
    text-align: left;
  }
  .one3d-sub-acc-title {
    font-size: 0.75rem;
    font-weight: 800;
    line-height: 1.125rem;
    color: #636363;
  }
  .one3d-sub-acc {
    display: flex;
    padding-left: 1.75rem;
    margin-bottom: 10px;
  }
  .one3d-sub-acc-img-section {
    padding-right: 0.625rem;
    flex: 0 0 40%;
  }
  .one3d-sub-acc-top {
    margin-bottom: 16px;
    position: relative;
  }
  .one3d-sub-acc-content {
    flex: 0 0 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .one3d-acc-view-btn {
    background: var(--ion-color-dark-contrast);
    border: 1px solid var(--ion-color-dark);
    color: var(--ion-color-dark);
    border-radius: 2px;
    padding: 6px 10px;
    font-size: 0.75rem;
  }
  .one3d-sub-acc-content-bottom {
    text-align: right;
  }
  .confirmation-modal,
  .one3d-save-share-popup,
  .one3d-login-modal {
    font-family: "Heebo";
    --border-radius: 1px;
  }
  .confirmation-modal {
    --max-height: 270px;
    --max-width: 450px;
    --backdrop-opacity: 0.8 !important;
  }
  .one3d-save-share-popup {
    /*--max-height: 380px;*/
    --height: auto;
    --max-width: 450px;
    --backdrop-opacity: 0.8 !important;
    padding: 0 10px;
  }
  .one3d-login-modal {
    padding: 0 10px;
    --max-width: 350px;
    --background: #fff;
    --backdrop-opacity: 0.8 !important;
  }
  .one3d-test-drive-modal {
    padding: 0 10px;
    --max-width: 350px;
    --background: transparent;
    --backdrop-opacity: 0.8 !important;
  }
  .one3d-login-modal-auto-pop {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 290px;
    padding-top: 40px;
  }
  .one3d-close-popup {
    text-align: right;
  }
  .one3d-close-popup ion-icon {
    font-size: 24px;
  }
  ion-select::part(icon) {
    content: url("assets/img/icons/co-down-arow-icon.svg");
    margin-left: 10px;
    // margin-right: 10px;
    opacity: 1;
  }
  ion-select::part(placeholder) {
    flex: none;
  }
  ion-modal.auto-height {
    &.bottom {
      align-items: flex-end;
    }
    --height: auto;
    .ion-page {
      position: relative;
      display: block;
      contain: content;
      .inner-content {
        max-height: 80vh;
        overflow: auto;
        padding: 10px;
      }
    }
  }
  .activeButton {
    background: var(--ion-color-primary);
    color: #fff;
  }
  .nonActiveButton {
    background: #636363;
    color: var(--ion-color-secondary-contrast);
  }
  .one-3d-disabled-pointer-events {
    opacity: 0.5;
    pointer-events: none;
  }
  button[disabled] {
    opacity: 0.4;
  }
  .one3d-build-name-popup {
    --max-height: 275px;
    --max-width: 400px;
    --backdrop-opacity: 0.8 !important;
    --border-radius: 1px;
  }
  .one3d-privacy-policy-modal {
    --height: 85%;
    --width: 85%;
    --border-radius: 1px;
  }
  // For Summary
  .one3d-summary-content ion-row,
  .one3d-summary-ext,
  .one3d-summary-row-margin ion-row:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  .one3d-config-breakup {
    .one3d-summary-content ion-row,
    .one3d-summary-row-margin ion-row:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
  .one3d-summary-descriptor-name {
    font-size: 0.75rem;
    font-weight: 800;
    color: #3e3e3e;
  }
  .one3d-summary-descriptor-value {
    font-size: 0.75rem;
    font-weight: 400;
    display: block;
    text-align: right;
    width: 100%;
  }
  .one3d-summary-descriptor-value-bold {
    font-weight: 800;
  }
  .one3d-summary-content {
    padding: 14px 12px;
  }
  .one3d-summary-ext-int-wrapper,
  .one3d-summary-emi,
  .one3d-summary-exchange {
    padding: 18px 12px;
  }
  .one3d-summary-sub-title {
    font-size: 14px;
    font-weight: 800;
    color: #3e3e3e;
    margin: 0 0 1rem 0;
  }
  .one3d-summary-item-name {
    font-size: 0.75rem;
    font-weight: 400;
  }
  .one3d-summary-item-value {
    font-size: 0.75rem;
    font-weight: 800;
  }
  .one3d-summary-delete-icon {
    margin-left: 18px;
  }
  .one3d-summary-total {
    position: relative;
    padding: 10px 12px;
  }
  .one3d-summary-total-title,
  .one3d-summary-total-value {
    font-size: 0.875rem;
    font-weight: 800;
  }
  .one3d-summary-total-value {
    display: block;
    text-align: right;
    width: 100%;
  }
  .one3d-summary-content-right-col {
    display: -webkit-flex;
    display: flex;
    display: -ms-flexbox;
    -ms-flex-align: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
    float: right;
  }
  .one3d-summary-terms-para {
    font-size: 0.625rem;
    font-weight: 400;
    color: #636363;
  }
  .one3d-config-breakup-top {
    text-align: right;
    padding: 14px 12px 5px;
  }
  .one3d-config-breakup-hide-btn {
    border: 1px solid #000000;
    border-radius: 2px;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 8px;
    color: black;
  }
  // Disclaimer
  .one3d-disclaimer-wrapper,
  .one3d-disclaimer-wrapper-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    right: 0;
    display: flex;
    justify-content: right;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    width: calc(100% - 350px);
  }
  ion-content.one3d-lhs-panel-open .one3d-disclaimer-wrapper {
    width: 100%;
  }
  .one3d-configurator-wrapper .one3d-disclaimer-wrapper {
    width: 100%;
  }
  .one3d-disclaimer-content {
    overflow: hidden;
    width: 100%;
  }
  .one3d-disclaimer-para,
  .one3d-disclaimer-btn {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: var(--ion-color-dark-contrast);
  }
  .one3d-disclaimer-btns {
    display: flex;
    align-items: center;
    min-width: max-content;
    justify-content: center;
    padding: 0 5px;
  }
  .one3d-disclaimer-btns span {
    color: var(--ion-color-dark-contrast);
  }
  .one3d-brand-icon {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }
  .one3d-brand-icon img {
    max-width: 120px;
    max-height: 12px;
  }
  .one3d-disclaimer-para {
    margin: 0;
    text-align: right;
    padding: 4px;
  }
  .one3d-disclaimer-btn {
    text-decoration: underline;
  }
  // Arrow Controls and pagination
  .one3d-ef-arrow-btn {
    --background: var(--ion-color-primary);
    --color: var(--ion-color-dark);
    --border-radius: 2px;
  }
  .one3d-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 2%;
    background-color: #d1d1d1;
  }
  // Lhs bottom row selected variant
  .one3d-lhs-bottom-selected-mobile {
    background: rgba(255, 255, 255, 0.9);
    display: none;
    width: 100%;
  }
  .one3d-lhs-bottom-selected-desktop {
    position: absolute;
    bottom: 0;
    width: 350px;
    left: 0;
    background: #ffffff;
    box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
  }
  .one3d-reset-position-btn {
    background: var(--ion-color-dark-contrast);
    font-size: 0.75rem;
    font-weight: 800;
    padding: 8px 10px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    color: var(--ion-color-dark);
    margin-left: 40px;
  }
  .one3d-reset-position-btn img {
    margin-left: 5px;
    line-height: 16px;
    vertical-align: middle;
  }
  .one3d-acc-close-btn {
    background-color: var(--ion-color-dark-contrast);
    color: var(--ion-color-dark);
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    margin: 28px auto 0 auto;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 2px;
  }
  .one3d-acc-close-btn img {
    height: auto;
    width: auto;
    margin-right: 6px;
  }
  .one3d-lhs-bottom-wrapper {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 2px;
      pointer-events: none;
      opacity: 0.3;
      background: linear-gradient(
        90deg,
        rgba(255, 249, 249, 0.6) 0%,
        #656366 11.25%,
        #656366 87.98%,
        #fffdfd 98.21%
      );
    }
  }
  .one3d-lhs-config-bottom-wrapper {
    box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
    &::before {
      content: "";
      position: absolute;
      top: -40px;
      width: 100%;
      height: 2px;
      pointer-events: none;
      // background: linear-gradient(
      //   0deg,
      //   #ffffff 0%,
      //   rgba(255, 255, 255, 0) 100%
      // );
    }
  }
  .one3d-acc-img-section-inside {
    position: relative;
    max-height: 80px;
    overflow: hidden;
    .one3d-acc-icon-addition {
      position: absolute;
      bottom: 0;
      width: 99%;
      text-align: right;
      padding: 0 4px;
      background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
      .one3d-acc-img-icon {
        top: 3px;
        width: 20px;
        position: relative;
      }
    }
  }
  ion-popover {
    --width: 182px;
    top: -12px;
  }
  ion-popover ion-select-popover ion-list.list-md {
    padding: 0;
  }
  ion-popover ion-item {
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    border-bottom: 0.5px solid rgba(191, 191, 191, 0.4);
    --min-height: 26px;
  }
  ion-popover ion-item ion-label {
    margin: 9px auto !important;
  }
  ion-popover ion-radio {
    --width: 5px;
  }
  ion-popover .item-radio-checked.sc-ion-select-popover-md {
    --background: white;
    --background-focused: white;
  }
  .one3d-extended-warranty-calendar {
    right: 0;
    left: auto;
    float: right;
    width: 260px;
    font-size: 10px;
    --font-size: 10px;
    border-radius: 6px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  }
  .sc-ion-buttons-md-s .button-has-icon-only.button-clear {
    width: 40px;
  }
  .calendar-days-of-week {
    font-size: 10px !important;
    line-height: 16px !important;
  }
  .calendar-day::after {
    width: 20px !important;
    height: 20px !important;
  }
  .calendar-day {
    padding: 8px 0px !important;
    font-size: 10px !important;
  }
  .datetime-calendar .calendar-action-buttons ion-item {
    font-size: 14px !important;
  }
  .one3d-close-popup {
    ion-icon {
      cursor: pointer;
    }
  }
  .one3d-tutorial-gif {
    background: #8b8b8b url("assets/img/one3d-tutorial-desktop.gif") no-repeat
      center/cover;
    height: 156px;
  }
  .new-pb-0 {
    padding-bottom: 0;
  }
  .new-pt-4 {
    padding-top: 4px;
  }
  .new-pt-10 {
    padding-top: 10px;
  }
  .pb-10 {
    padding-bottom: 10px;
  }
  .one3d-insurance-select-box-wrapper {
    margin-bottom: 10px;
    .one3d-insurance-select-box-label {
      font-size: 12px;
      font-weight: 800;
      line-height: 18px;
      display: block;
      margin: 0 0 6px;
      text-transform: uppercase;
      color: var(--ion-color-gray-dark);
    }
    .one3d-insurance-select-box {
      position: relative;
      .one3d-insurance-select {
        width: 100%;
        height: 40px;
        border-radius: 2px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: 0 16px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: var(--ion-color-secondary-contrast);
        border: 1px solid var(--ion-color-gray-light);
        &:focus {
          outline: 0;
        }
      }
      .one3d-bottom-arrow-angle {
        top: 50%;
        right: 16px;
        line-height: 0;
        position: absolute;
        transform: translateY(-50%);
      }
    }
  }
  .one3d-mobile-nudge {
    display: none;
  }
  // For Responsive
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .one3d-lhs-bottom-selected-desktop .one3d-lhs-bottom-selected,
    .one3d-lhs-config-bottom-selected {
      display: none;
    }
    ion-popover {
      top: 12px;
    }
    .one3d-lhs-bottom-selected-desktop {
      width: 100%;
    }
    .one3d-brand-icon img {
      max-width: 68px;
    }
    .one3d-brand-icon {
      margin-left: 0px;
      padding: 0 15px;
    }
    .one3d-sidePanelModal {
      flex: 0 0 100% !important;
      --max-width: 100% !important;
      // --height: 45%;
      --height: 100%;
      --width: 100%;
      --background: transparent;
      top: 30px;
    }
    // don't do anything on this yet
    .one3d-sidePanelModal-summary {
      flex: 0 0 100% !important;
      --max-width: 100% !important;
      --width: 100% !important;
      max-width: 100% !important;
    }
    .one3d-accessoriesModal {
      flex: 0 0 100% !important;
      --max-width: 100% !important;
    }
    .one3d-scrollable-wrapper {
      /*max-height: 40vh !important;
      padding-bottom: 90px !important;*/
      max-height: calc(40vh - 30px);
    }
    .one3d-keyFeatures-modal {
      --height: 350px;
      --width: 90%;
      --border-radius: 2px;
    }
    .one3d-ef-arrow-btn {
      height: 36px;
      width: 36px;
      --padding-start: 0;
      --padding-end: 0;
    }
    .one3d-visualizer-lhs .one3d-disclaimer-wrapper-mobile {
      bottom: 38px;
    }
    .one3d-disclaimer-wrapper-mobile {
      bottom: 34px;
    }
    .one3d-disclaimer-wrapper-mobile .one3d-disclaimer-para {
      text-align: center;
      white-space: nowrap;
      /* animation properties */
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      -moz-animation: my-animation 30s linear infinite;
      -webkit-animation: my-animation 30s linear infinite;
      animation: my-animation 30s linear infinite;
    }
    /* for Firefox */
    @-moz-keyframes my-animation {
      from {
        -moz-transform: translateX(100%);
      }
      to {
        -moz-transform: translateX(-140%);
      }
    }
    /* for Chrome */
    @-webkit-keyframes my-animation {
      from {
        -webkit-transform: translateX(100%);
      }
      to {
        -webkit-transform: translateX(-140%);
      }
    }
    @keyframes my-animation {
      from {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
      }
      to {
        -moz-transform: translateX(-140%);
        -webkit-transform: translateX(-140%);
        transform: translateX(-140%);
      }
    }
    .confirmation-modal {
      --max-height: 300px;
      --max-width: 95%;
    }
    // Lhs bottom selected variant
    .one3d-lhs-bottom-selected-desktop {
      width: 100%;
    }
    .one3d-lhs-bottom-selected-mobile {
      position: absolute;
      top: 0;
      display: block;
    }
    .one3d-lhs-bottom-selected-mobile .one3d-lhs-config-bottom-selected {
      display: flex;
    }
    .idea-1 .one3d-lhs-config-bottom-selected.one3d-bg-black {
      background-color: rgba(0, 0, 0, 0.7) !important;
      .one3d-lhs-config-bottom-details {
        h4,
        p {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    .one3d-lhs-bottom-selected-desktop .one3d-lhs-config-bottom-selected {
      display: none;
    }
    .one3d-accessories-nav {
      padding: 0px 16px;
    }
    .one3d-accessories-nav-arrow {
      padding: 3px;
      border: 1px solid var(--ion-color-dark);
      border-radius: 2px;
      height: 26px;
      margin: 12px 10px 0px 0px;
    }
    .one3d-reset-position-btn {
      position: absolute;
      // left: 50%; // keeping view 360 on right side for mobile device as discussed with jawahar & anila
      right: 0%;
      transform: translateX(-20%);
      bottom: 30px;
      padding: 4px 10px;
      margin-left: auto;
    }
    .one3d-lhs-config-bottom-wrapper {
      box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.1);
      &::before {
        content: "";
        position: absolute;
        top: -40px;
        width: 100%;
        height: 40px;
        pointer-events: none;
        background: none;
      }
    }
    .one3d-disclaimer-wrapper-mobile {
      width: 100%;
    }
    .one3d-build-name-popup {
      --max-height: 275px;
      --max-width: 90%;
    }
    /*.one3d-save-share-popup {
      --max-width: 90%;
    }*/
    .one3d-lhs-config-bottom-wrapper {
      background-color: transparent;
    }
    .one3d-config-canvas-column .one3d-color-palette {
      bottom: 5%;
    }
    .one3d-acc-close-btn {
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      padding: 4px 6px;
    }
    .one3d-avl-wrapper {
      padding-bottom: 60px;
    }
    .one3d-configurator-lhs .one3d-avl-wrapper {
      padding-bottom: 20px;
    }
    .one3d-acc-add-btn {
      padding: 9px 34px;
    }
    .one3d-acc-remove-btn {
      padding: 10px 21px;
    }
    .one3d-tutorial-gif {
      background: #8b8b8b url("assets/img/one3d-tutorial-mobile.gif") no-repeat
        center/cover;
    }
    .one3d-login-modal-auto-pop {
      padding: 0;
      justify-content: center;
      align-items: center;
    }
    // new-design-mobile-responsive//
    .one3d-lhs-bottom-wrapper.one3d-menubar-black {
      background-color: var(--ion-color-dark) !important;
      h4,
      p,
      button {
        font-size: 12px;
        font-weight: 800;
        color: #fff;
        line-height: 16px;
      }
      button {
        margin-left: 5px;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
    .one3d-lhs-bottom-selected.one3d-bg-dark {
      background-color: rgba(0, 0, 0, 0.7);
    }
    .one3d-lhs-bottom-price span,
    .one3d-lhs-config-bottom-price span {
      color: #ccc;
    }
    .one3d-book-test-drive-btn-mobile.one3d-rhs-header-btn {
      position: relative;
      overflow: hidden;
      bottom: 0 !important;
      left: 0;
      .one3d-new-td-btn {
        width: 140px;
        height: 32px;
        padding: 4px 4px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: var(--ion-color-primary);
        .title {
          font-size: 12px;
          font-weight: 600;
          color: #fff;
        }
      }
      .one3d-test-drive-btn-only {
        width: 110px;
      }
    }
    .one3d-rhs-header-btn .one3d-book-test-drive-btn-mobile-box {
      .title {
        border-left: none !important;
      }
    }
    .one3d-lhs-config-bottom-details {
      width: calc(100% - 140px);
    }
    // offer-btn//
    .one3d-offer-btn-mobile {
      padding: 8px 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid #fff;
      line-height: 8px;
      color: #fff;
      .offer-btn {
        display: flex;
        align-items: center;
      }
      span {
        margin-right: 6px;
      }
    }
    // offer-btn//
    .one3d-mi-wrapper.one3d-micro-panel-bottom {
      left: 0;
      top: initial;
      bottom: 36px;
      right: initial;
      // transform: rotate(-90deg);
    }
    .one3d-mobile .one3d-mi-wrapper.one3d-micro-panel-bottom {
      bottom: 0px;
    }
    // white-toggle-btn//
    .one3d-lhs-panel-control-wrapper.one3d-white-bottom-toggle {
      left: initial;
      right: 0;
      transform: initial;
      button.one3d-white-bottom-toggle-btn {
        background-color: #fff;
        span {
          color: #000;
        }
      }
    }
    .one3d-lhs-panel-control-wrapper.one3d-white-bottom-toggle {
      bottom: 38px;
    }
    .one3d-white-bottom-toggle .one3d-lhs-panel-open-wrapper {
      width: auto;
    }
    .one3d-white-bottom-toggle .one3d-lhs-panel-close-mobile {
      width: auto;
      min-width: auto;
      min-height: 28px;
      padding: 4px 8px;
    }
    .one3d-white-bottom-toggle .one3d-lhs-panel-close-mobile span {
      margin-right: 0 !important;
      left: 0;
    }
    .one3d-white-bottom-toggle .one3d-chevron-bottom {
      letter-spacing: 0.2em;
      background: var(--ion-color-primary);
      border-radius: 2px 2px 0px 0px;
      padding: 8px;
      font-size: 10px;
      color: white;
      text-transform: uppercase;
      width: 40px;
    }
    .one3d-white-bottom-toggle .one3d-lhs-panel-open-wrapper {
      position: initial;
      transform: initial;
    }
    // nudges//
    .one3d-blur-btn-td {
      z-index: 9;
    }
    .one3d-nudge {
      background: #fff;
      position: absolute;
      bottom: 64px;
      right: 10%;
      border-left: 4px solid #487f70;
      max-width: 190px;
      width: 100%;
      z-index: 9;
    }
    .one3d-mobile-nudge {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 1px 2px 8.1px 0px #00000033;
    }
    .one3d-nudge::after {
      content: "";
      position: absolute;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      left: 20px;
      border-top: 20px solid #fff;
    }
    .one3d-nudge p {
      font-weight: 500 !important;
      font-size: 12px;
      line-height: 16px;
      color: #000 !important;
      margin: 0;
      padding: 5px 14px;
      border-right: 1px solid #bfbfbf;
    }
    .one3d-nudge img {
      margin-left: 5px;
    }
    .one3d-nudge.bottom-right::after {
      border-top: 20px solid #fff;
      bottom: -20px;
      right: 15px;
      left: initial;
    }
    .top-right {
      top: 52px;
      right: 3%;
      bottom: auto;
      max-width: 220px;
    }
    .top-right-cc {
      top: 88px;
    }
    .one3d-nudge.top-right::after {
      top: -8px;
      right: 30px;
      bottom: auto;
      left: auto;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: transparent;
      border-bottom: 10px solid #fff;
    }
    // test-drive-animation//
    .rotate {
      animation: rotation 8s infinite linear;
    }
    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      20% {
        transform: rotate(45deg);
      }
      40% {
        transform: rotate(0deg);
      }
      60% {
        transform: rotate(-45deg);
      }
      80% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(45deg);
      }
      80% {
        transform: rotate(0deg);
      }
      60% {
        transform: rotate(-45deg);
      }
      40% {
        transform: rotate(0deg);
      }
      20% {
        transform: rotate(45deg);
      }
      0% {
        transform: rotate(0deg);
      }
    }
    .one3d-mobile .one3d-mi-wrapper.one3d-micro-panel-bottom {
      bottom: 0;
    }
    // blur-code//
    .one3d-nudge-blur {
      backdrop-filter: blur(4px);
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 9;
    }
    // interior-exterior-new-btn//
    .one3d-interior-exterior-new-btn {
      top: 0;
      right: 0;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.7);
      button {
        padding: 9px 9px 6px;
        color: #000;
        font-size: 12px;
        font-weight: 800;
        line-height: 12px;
        display: flex;
        align-items: center;
        height: 38px;
        span {
          margin-right: 6px;
          img {
            max-width: initial;
          }
        }
      }
    }
    .one3d-view-break-btn-new {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      position: relative;
      top: 2px;
      img {
        margin-right: 5px;
      }
      span {
        font-size: 10px;
        font-weight: normal;
        line-height: 14px;
        color: #fff;
        border-bottom: 1px solid #fff;
      }
    }
    // shimmer-code//
    .one3d-shimmer-text::after {
      top: 0;
      left: -160px;
      content: "";
      width: 48px;
      height: 80px;
      position: absolute;
      pointer-events: none;
      transform-origin: bottom left;
      transform: skew(-27deg);
      background-color: hsla(0, 0%, 100%, 0.4);
      box-shadow: 0 0 24px 2px hsla(0, 0%, 100%, 0.2);
      animation: shimmering 6s ease-in 1s infinite normal forwards;
    }
    // .one3d-mobile {
    //   .one3d-visualizer-seating-rows {
    //     display: none;
    //   }
    // }
  }
  // For Ipad
  @media screen and (min-width: 1100px) and (max-width: 1360px) and (orientation: landscape) {
    .one3d-seating-rows {
      padding: 6px 10px;
    }
    .one3d-visualizer-seating-rows {
      top: 6px;
      left: -184px;
      position: absolute;
    }
    .one3d-visualizer-seating-rows .one3d-seating-rows {
      padding: 0.75rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) and (orientation: landscape) {
    .one3d-seating-rows {
      padding: 6px 10px;
    }
    .one3d-visualizer-seating-rows {
      top: -50px;
      left: -100px;
      position: absolute;
    }
    .one3d-visualizer-seating-rows .one3d-seating-rows {
      padding: 0.75rem;
    }
  }
  .pb-1 {
    padding-bottom: 1px;
  }
  .one3d-discalaimer-section-static {
    z-index: 2;
    width: 100%;
    bottom: 34px;
    padding: 12px 16px;
    text-align: left;
    position: absolute;
    background: #fff;
    h4 {
      margin: 0 0 4px;
      line-height: 1;
      ion-text {
        display: block;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        color: var(--ion-color-primary-contrast);
      }
    }
    p {
      margin: 0;
      ion-text {
        display: block;
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        color: var(--ion-color-primary-contrast);
      }
    }
  }
}
.one3d-delete-icon {
  cursor: pointer;
}
/* The container */
.new-label-checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.new-label-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.new-label-checkbox-container .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  border: 1px solid var(--ion-color-gray-light-new);
  background-color: var(--ion-color-secondary-contrast);
}
/* On mouse-over, add a grey background color */
.new-label-checkbox-container:hover input ~ .checkmark {
  background-color: var(--ion-color-secondary-contrast);
}
/* When the checkbox is checked, add a yellow background */
.new-label-checkbox-container input:checked ~ .checkmark {
  border-color: var(--ion-color-primary);
  background-color: var(--ion-color-primary);
}
/* Create the checkmark/indicator (hidden when not checked) */
.new-label-checkbox-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.new-label-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.new-label-checkbox-container .checkmark:after {
  top: 2px;
  left: 6px;
  width: 3px;
  height: 8px;
  border: solid var(--ion-color-primary-contrast);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
ion-loading.one3d-jeep-loader {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
  .loading-wrapper {
    background: transparent !important;
    box-shadow: none !important;
  }
}
ion-loading.one3d-jeep-loader-opaque {
  --max-width: 100%;
  --backdrop-opacity: var(--ion-backdrop-opacity, 1);
  .loading-wrapper {
    background: transparent !important;
    box-shadow: none !important;
    padding-inline-start: 4px !important;
    padding-inline-end: 4px !important;
  }
  ion-img {
    margin: auto;
    max-width: 200px;
  }
}
.one3d-display-none {
  display: none !important;
}
.one3d-mobile-seating-config {
  display: none;
}
@media only screen and (max-width: 767px) {
  .one3d-door-remove-notification {
    right: 74px;
    top: 86px;
    .one3d-door-remove-notify {
      ion-text {
        font-size: 12px;
      }
    }
  }
  .one3d-mobile-seating-row-box {
    .one3d-mobile-seat-rows {
      position: absolute;
      display: block;
      margin-top: -4px;
    }
  }
  .one3d-mobile-seat-rows-toggle {
    display: block;
    width: 48px;
    height: 48px;
    margin-left: -10px;
    --border-radius: 3px;
    --background: rgba(var(--ion-color-secondary-contrast-rgb), 0.9);
  }
  .one3d-mobile-seat-rows-toggle::part(native) {
    --color: #000;
  }
  .one3d-mobile-seat-rows ion-fab-list {
    margin-top: 0;
    line-height: 1;
    min-height: 48px;
    overflow: hidden;
    border-radius: 2px;
    margin-right: 62px;
    background: rgba(var(--ion-color-secondary-contrast-rgb), 0.9);
  }
  .one3d-mobile-seat-rows ion-fab-list.ios {
    position: absolute;
    top: -48px;
    right: -32px;
  }
  .one3d-mobile-seat-rows ion-fab-list ion-fab-button {
    width: 50px;
    --border-radius: 0;
    --background: transparent;
    --box-shadow: none;
    position: relative;
  }
  .one3d-mobile-seat-rows ion-fab-list ion-fab-button ion-text {
    display: flex;
    margin-top: 4px;
    flex-direction: column;
  }
  .one3d-seating-rows-wrapper {
    display: none;
  }
  .one3d-mobile-seat-rows {
    position: relative;
    top: auto;
  }
  .one3d-mobile-seat-rows-wrap {
    margin-top: 4px;
  }
  .one3d-mobile-seat-rows {
    .one3d-mobile-seat-rows-toggle {
      margin-left: -8px;
    }
  }
  .one3d-mobile-seating-config {
    position: absolute;
    bottom: -98px;
    right: 12px;
    display: block;
  }
}
/** Bug fixing for Button for Mobile ***/
@media screen and (max-width: 991px) {
  .one3d-lhs-panel-close-mobile {
    letter-spacing: 0.1em;
    width: 250px;
    min-height: 45px;
  }
}
.packIcon {
  position: absolute;
  right: 16px;
  bottom: 30px;
}
.packIconrow {
  display: block;
  width: 108px;
  text-align: right;
}
@media screen and (max-width: 1100px) {
  .packIconrow {
    width: 77px;
  }
}
@media screen and (max-width: 750px) {
  .packIconrow {
    width: 77px;
  }
  .packIcon {
    bottom: 5%;
  }
  .packIcon2 {
    bottom: 10px;
  }
  .one3d-color-palette {
    left: 0% !important;
    transform: translate(0%) !important;
    margin-left: 4px;
  }
}
@media screen and (max-width: 750px) {
  .one3d-lhs-panel-close-mobile {
    text-transform: uppercase;
    white-space: nowrap;
  }
  .one3d-lhs-panel-close-mobile span {
    left: -3px;
    position: relative;
  }
}
.one3d-previous-arrow {
  background: #636363;
  margin: 0;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.one3d-previous-arrow span {
  margin: 0 6px;
}
.one3d-acc-desc-new p span {
  cursor: pointer;
}
.one3d-key-features-info span {
  cursor: pointer;
  display: inline-block;
}
.col-6-remove-space {
  height: 37px;
  overflow: hidden;
}
.one3d-acc-description-new {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  width: 100%;
}
.one3d-acc-description-new p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  margin: 0 0 10px 0;
  line-height: 12px;
}
.one3d-acc-description-new p b {
  font-weight: 600;
  font-size: 11px;
}
.one3d-acc-description-new span {
  font-weight: 600;
  font-size: 10px;
  cursor: pointer;
  display: inline-block;
}
/*--offer popup--*/
.one3d-offer-modal {
  --backdrop-opacity: 0.8 !important;
  --height: auto;
  --max-width: 788px;
  --width: 100%;
  --background: none;
}
.one3d-offer-popup-wrapper {
  width: 100%;
}
.one3d-offer-popup-inner {
  /*position: fixed; left:50%; top:50%; transform: translate(-50%, -50%); z-index: 11;*/
  width: 100%;
  max-width: 100%;
}
.one3d-offer-popup-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.one3d-offer-popup-bg {
  background: #fff;
  width: 100%;
  overflow: hidden;
}
.one3d-offer-popup-row1 {
  width: 100%;
  float: left;
  background: var(--ion-color-primary);
  min-height: 90px;
  position: relative;
  display: flex;
  justify-content: end;
  align-items: self-end;
}
.one3d-offer-popup-row1-img {
  // position: absolute;
  // left: 60px;
  // bottom: -66px;
  // max-width: 355px;
  position: absolute;
  left: 60px;
  bottom: -21px;
  max-width: 225px;
}
.one3d-offer-popup-row1-img img {
  max-height: 220px;
}
.one3d-offer-popup-row1-info {
  width: 100%;
  text-align: right;
  padding: 13px 30px 13px 350px;
  font-size: 24px;
  line-height: 25px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--ion-color-dark);
  position: absolute;
  right: 0;
  bottom: 0;
}
.one3d-offer-popup-row1-info p {
  width: 100%;
  margin: 4px 0;
  padding: 0;
  color: #fff;
}
.one3d-offer-popup-row1-info p span {
  font-weight: 800;
}
.one3d-offer-popup-row2 {
  width: 100%;
  float: left;
  min-height: 100px;
  max-height: calc(85vh - 100px);
  overflow: auto;
}
.one3d-offer-popup-row2::-webkit-scrollbar-track {
  background-color: #dddddd;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.one3d-offer-popup-row2::-webkit-scrollbar {
  width: 5px;
  background-color: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.one3d-offer-popup-row2::-webkit-scrollbar-thumb {
  background-color: #808080;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.one3d-offer-single-card ul {
  text-align: center;
}
.one3d-offer-single-card li {
  width: 70% !important;
  float: none !important;
  display: inline-block;
  vertical-align: top;
}
.one3d-offer ul {
  width: 100%;
  float: left;
  // margin: 80px 0 20px 0;
  margin: 20px 0 20px 0;
  padding: 0 10px;
  list-style-type: none;
}
.one3d-offer li {
  width: 50%;
  float: left;
  list-style-type: none;
  margin: 12px 0;
  padding: 0 10px;
  text-align: left;
}
.one3d-offer li .inner {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  background: #fff;
  text-align: left;
  color: #212121;
  padding: 15px;
  height: 260px;
  overflow: auto;
  -webkit-box-shadow: 3px 2px 22px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 3px 2px 22px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 3px 2px 22px 0px rgba(0, 0, 0, 0.2);
}
.one3d-offer li .inner::-webkit-scrollbar-track {
  background-color: #dddddd;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.one3d-offer li .inner::-webkit-scrollbar {
  width: 3px;
  background-color: none;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}
.one3d-offer li .inner::-webkit-scrollbar-thumb {
  background-color: #808080;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.one3d-offer-h1 {
  font-size: 14px;
  color: #212121;
  font-weight: 800;
  width: 100%;
  margin-bottom: 8px;
}
.one3d-offer-h2 {
  font-size: 18px;
  line-height: 24px;
  color: #212121;
  font-weight: 800;
  width: 100%;
  margin-bottom: 15px;
}
.one3d-offer-h3 {
  font-size: 14px;
  font-weight: 400;
  color: #3e3e3e;
  line-height: 17px;
  width: 100%;
  margin-bottom: 10px;
}
.one3d-offer-h4 {
  font-size: 12px;
  font-weight: 400;
  color: #7c7c7c;
  line-height: 15px;
  width: 100%;
  margin-bottom: 0px;
}
.one3d-offer-h4 i {
  font-style: normal;
}
.one3d-offer-h4 span {
  display: inline-block;
  font-weight: 500;
  color: #3e3e3e;
  font-size: 11px;
  margin-left: 3px;
  cursor: pointer;
}
.one3d-offer-close-popup {
  position: absolute;
  right: 0;
  top: 0;
  margin: 11px 15px;
}
.one3d-offer-close-popup button {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
}
.one3d-offer-listing {
  width: 100%;
  float: left;
  padding: 10px 12px;
}
.one3d-offer-listing ul {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.one3d-offer-listing li {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
  // border-bottom: 1px solid #ddd;
  position: relative;
}
.one3d-offer-listing li::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  z-index: 2;
  background: linear-gradient(
    90deg,
    rgba(255, 249, 249, 0.6) 0%,
    #656366 11.25%,
    #656366 87.98%,
    #fffdfd 98.21%
  );
  opacity: 0.3;
  left: 0;
  right: 0;
}
.one3d-offer-listing li:last-child:after {
  display: none;
}
.one3d-offer-listing li .inner {
  width: 100%;
  float: left;
  padding: 15px 0;
}
.one3d-offer-listing li .inner {
  .one3d-offer-h1 {
    font-size: 14px;
    color: #212121;
    font-weight: 800;
    width: 100%;
    margin-bottom: 8px;
  }
  .one3d-offer-h2 {
    font-size: 16px;
    line-height: 23px;
    color: #000;
    font-weight: 800;
    width: 100%;
    margin-bottom: 12px;
  }
  .one3d-offer-h3 {
    font-size: 12px;
    font-weight: 500;
    color: #3e3e3e;
    line-height: 18px;
    width: 100%;
    margin: 5px 0;
  }
  .one3d-offer-h4 {
    font-size: 12px;
    font-weight: 400;
    color: #7c7c7c;
    line-height: 15px;
    width: 100%;
  }
  .one3d-offer-h4 i {
    font-style: normal;
  }
  .one3d-offer-h4 span {
    display: inline-block;
    font-weight: 500;
    color: #3e3e3e;
    font-size: 11px;
    margin-left: 3px;
    cursor: pointer;
  }
}
.one3d-offer-listing-border {
  width: 100%;
  float: left;
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(255, 249, 249, 0.6) 0%,
    #656366 11.25%,
    #656366 87.98%,
    #fffdfd 98.21%
  );
  opacity: 0.3;
}
.one3d-offer-disclaimer {
  margin: 15px 0;
  width: 100%;
  float: left;
}
.one3d-offer-disclaimer-left {
  width: 100%;
  float: left;
  text-align: right;
}
.one3d-offer-disclaimer-left button {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-size: 10px;
  font-weight: 600;
  color: #636363;
  text-decoration: underline;
  text-align: right;
}
.one3d-offer-disclaimer-right {
  width: 100%;
  float: left;
  margin-top: 10px;
  font-size: 11px;
  color: #3e3e3e;
  line-height: 14px;
  text-align: left;
}
.one3d-offer-disclaimer-right p {
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 816px) {
  .one3d-offer-modal {
    max-width: 100%;
  }
  .one3d-offer-popup-wrapper {
    width: 90%;
    margin: auto;
  }
  .one3d-offer-popup-row2 {
    max-height: calc(70vh - 150px);
  }
  .one3d-offer-h2 {
    font-size: 16px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 750px) {
  .one3d-offer li {
    width: 100%;
  }
  .one3d-offer-single-card li {
    width: 100% !important;
  }
  .one3d-offer li .inner {
    height: auto;
  }
  .one3d-offer-popup-row1-img {
    max-width: 265px;
    left: -70px;
    bottom: -40px;
  }
  .one3d-offer-popup-row1 {
    min-height: 120px;
    padding-top: 30px;
  }
  .one3d-offer-popup-row1-info {
    padding: 10px 10px 10px 150px;
    font-size: 20px;
  }
  .one3d-offer ul {
    margin-top: 40px;
  }
  .one3d-offer-h1 {
    font-size: 12px;
  }
  .one3d-offer-h2 {
    font-size: 16px;
    line-height: 20px;
  }
  .one3d-offer-h3 {
    font-size: 12px;
    line-height: 14px;
  }
  .one3d-offer-h4 {
    font-size: 12px;
    line-height: 14px;
  }
  .one3d-offer-close-popup {
    margin: 10px;
  }
  .one3d-offer-popup-row2::-webkit-scrollbar-thumb {
    background-color: #ff9700;
  }
  .one3d-offer-h1,
  .one3d-offer-h2,
  .one3d-offer-h3,
  .one3d-offer-h4 {
    -webkit-line-clamp: initial;
  }
  body .one3d-lhs-config-bottom-btn button span,
  body .one3d-lhs-config-bottom-title {
    font-size: 11px;
  }
  body .one3d-lhs-bottom-price,
  body .one3d-lhs-config-bottom-price {
    font-size: 10px;
    font-weight: normal;
  }
  body .one3d-seating-rows-wrapper {
    margin-right: 56px;
  }
}
@media only screen and (max-width: 320px) {
  .one3d-offer-popup-row1-info {
    font-size: 14px;
    line-height: 18px;
  }
}
.full-btn {
  flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%) !important;
  width: 100% !important;
  max-width: 100% !important;
}
.one3d-test-drive-wrap-new {
  z-index: 2;
  right: 25px;
  bottom: 34px;
  position: absolute;
}
.one3d-test-drive-btn-new {
  background-color: var(--ion-color-primary);
  button {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 14px 33px;
    position: relative;
    overflow: hidden;
    color: #fff;
    &::after {
      top: 0;
      left: -160px;
      content: "";
      width: 48px;
      height: 80px;
      position: absolute;
      pointer-events: none;
      transform-origin: bottom left;
      -webkit-transform: skew(-27deg);
      -moz-transform: skew(-27deg);
      -ms-transform: skew(-27deg);
      transform: skew(-27deg);
      background-color: hsla(0, 0%, 100%, 0.4);
      box-shadow: 0 0 24px 2px hsl(0deg 0% 100% / 20%);
      -webkit-animation: shimmering 6s ease-in 1s infinite normal forwards;
      -moz-animation: shimmering 6s ease-in 1s infinite normal forwards;
      -ms-animation: shimmering 6s ease-in 1s infinite normal forwards;
      animation: shimmering 6s ease-in 1s infinite normal forwards;
    }
  }
}
@-webkit-keyframes shimmering {
  0% {
    -webkit-transform: translateX(-148px) skew(-27deg);
    -moz-transform: translateX(-148px) skew(-27deg);
    -ms-transform: translateX(-148px) skew(-27deg);
    transform: translateX(-148px) skew(-27deg);
  }
  33% {
    -webkit-transform: translateX(500px) skew(-27deg);
    -moz-transform: translateX(500px) skew(-27deg);
    -ms-transform: translateX(500px) skew(-27deg);
    transform: translateX(500px) skew(-27deg);
  }
  to {
    -webkit-transform: translateX(500px) skew(-27deg);
    -moz-transform: translateX(500px) skew(-27deg);
    -ms-transform: translateX(500px) skew(-27deg);
    transform: translateX(500px) skew(-27deg);
  }
}
@keyframes shimmering {
  0% {
    -webkit-transform: translateX(-148px) skew(-27deg);
    -moz-transform: translateX(-148px) skew(-27deg);
    -ms-transform: translateX(-148px) skew(-27deg);
    transform: translateX(-148px) skew(-27deg);
  }
  33% {
    -webkit-transform: translateX(500px) skew(-27deg);
    -moz-transform: translateX(500px) skew(-27deg);
    -ms-transform: translateX(500px) skew(-27deg);
    transform: translateX(500px) skew(-27deg);
  }
  to {
    -webkit-transform: translateX(500px) skew(-27deg);
    -moz-transform: translateX(500px) skew(-27deg);
    -ms-transform: translateX(500px) skew(-27deg);
    transform: translateX(500px) skew(-27deg);
  }
}
//tooltip-test-drive//
/* nudge-css */
.one3d-nudge-test-drive-new {
  left: 50%;
  bottom: 64px;
  display: flex;
  min-width: 232px;
  padding: 3px 2px;
  position: absolute;
  background: #fff;
  border-radius: 4px;
  align-items: center;
  transform: translateX(-50%);
  border-left: 3px solid #ffba00;
}
.one3d-nudge-test-drive-new p {
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: #000;
  margin: 0;
  padding: 0 14px;
  position: relative;
  width: calc(100% - 30px);
  span {
    font-weight: 700;
  }
}
.one3d-nudge-test-drive-new p::after {
  right: 0;
  top: 50%;
  width: 1px;
  content: "";
  height: 80%;
  position: absolute;
  transform: translateY(-50%);
  background-color: #bfbfbf;
}
.one3d-nudge-close {
  width: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.one3d-nudge-test-drive-new::after {
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 12px solid #fff;
  transform: translateX(-50%);
  position: absolute;
  bottom: -12px;
  content: "";
  left: 50%;
}
.one3d-offers-footer {
  float: left;
  width: 100%;
  display: flex;
  padding: 10px 30px;
  background: #fff;
  justify-content: flex-end;
  box-shadow: 0px -5px 4px 0px #92929240;
  .one3d-test-drive-btn-new {
    max-width: max-content;
  }
}
@media screen and (min-width: 768px) and (max-width: 1360px) and (orientation: landscape) {
  .one3d-test-drive-wrap-new {
    right: 8px;
  }
  .one3d-test-drive-btn-new button {
    font-size: 13px;
    padding: 14px 10px;
  }
  .plt-ipad body .one3d-visualizer-seating-rows {
    top: -50px;
    left: -100px;
  }
}
@media screen and (min-width: 1366px) and (orientation: landscape) {
  .plt-tablet body .one3d-mi-wrapper {
    left: 66%;
  }
  .plt-tablet body .one3d-lhs-panel-open .one3d-mi-wrapper {
    left: 50%;
  }
}
.one3d-loader-bottom-text {
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  margin-top: -16px;
  font-weight: normal;
}
// #one3d-canvas,
// #one3dCanvas {
//   width: 100% !important;
//   height: 100% !important;
// }
.ONE3D-login-modal {
  margin: auto;
  // max-width: 400px;
  --background: transparent;
  --box-shadow: none;
  background-color: transparent;
}
.one3d-lhs-config-bottom-details {
  flex: 0 0 auto;
  width: calc(100% - 100px);
}
.one3d-lhs-config-bottom-btn {
  width: 100px;
  flex: 0 0 auto;
  text-align: right;
}
.one3d-mobile-features-main-container {
  display: none;
}
.h-100 {
  height: 100%;
}
.one3d-mf-panel-ion-menu {
  --background: rgba(0, 0, 0, 0.6);
  --width: 70%;
}
.mobile-view {
  display: none !important;
}
.one3d-config-canvas-bottom {
  left: 350px;
}
.one3d-lhs-panel-open {
  .one3d-config-canvas-bottom {
    left: 0;
  }
}
.one3d-lhs-panel-open.one3d-mobile .one3d-tutorial-new {
  left: 50%;
  bottom: 54%;
}
.one3d-lhs-panel-open .one3d-tutorial-new {
  bottom: 20%;
  left: 50%;
}

// New Mobile UI for Feature Showcase Reels Starts Here
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .one3d-mobile-features-main-container {
    display: block;
  }
  .mobile-view {
    display: block !important;
  }
  .d-none {
    display: none !important;
  }
  .one3d-mobile-features-main-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
  }
  .one3d-mf-main {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .one3d-mf-panel-wrapper {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(20px);
  }
  .one3d-mf-panel-wrap {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    position: absolute;
    justify-content: flex-end;
  }
  .one3d-mf-accordion {
    padding: 0 20px 20px 20px;
    height: calc(100% - 46px);
    overflow: auto;
  }
  .one3d-mf-panel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .one3d-mf-list {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    .one3d-mf-list-li {
      text-align: right;
      position: relative;
      padding: 8px 0 8px 28px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      &::after {
        width: 3px;
        right: -8px;
        bottom: 2px;
        content: "";
        height: 80%;
        position: absolute;
        border-radius: 10px;
        background-color: rgba(251, 251, 251, 0.1);
      }
      &::before {
        top: 50%;
        left: 8px;
        content: "";
        width: 16px;
        height: 16px;
        opacity: 0.5;
        position: absolute;
        filter: grayscale(1);
        background-size: contain;
        transform: translateY(-50%);
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: inherit;
        background-image: url(assets/img/icons/one3d-play-button-yellow.svg);
      }
      .one3d-mf-list-text {
        font-size: 14px;
        color: var(--ion-color-gray-light);
      }
    }
    .active {
      .one3d-mf-list-text {
        color: #3fcca6;
      }
      &::before {
        opacity: 1;
        filter: grayscale(0);
      }
      &::after {
        background-color: #3fcca6;
      }
    }
  }
  .one3d-mf-count {
    font-size: 12px;
    font-weight: 600;
    padding: 6px 10px;
    align-items: center;
    border-radius: 30px;
    display: inline-flex;
    margin: 10px 10px 10px 16px;
    justify-content: flex-start;
    background-color: rgba(0, 0, 0, 0.6);
    color: var(--ion-color-secondary-contrast);
  }
  .one3d-mf-close-panel {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  .one3d-features-mobile-reel-view {
    .one3d-mobile {
      .one3d-canvas-column {
        max-height: 100% !important;
        background-color: #000;
      }
      .one3d-visualizer-lhs {
        display: none;
      }
      .one3d-lhs-btn-row {
        display: none;
      }
    }
  }
  .one3d-mf-accordion-heading {
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    position: relative;
    border-bottom: none;
    color: rgba(222, 222, 222, 0.9);
    &::before {
      left: 24px;
      top: 13px;
      content: "";
      width: 2px;
      height: 13px;
      position: absolute;
      border-radius: 6px;
      background-color: var(--ion-color-secondary-contrast);
    }
    &::after {
      left: 18px;
      top: 18px;
      content: "";
      width: 14px;
      height: 2px;
      position: absolute;
      border-radius: 6px;
      background-color: var(--ion-color-secondary-contrast);
    }
    ion-icon {
      display: none;
    }
    ion-label {
      margin-right: 0;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
  .accordion-expanded {
    .one3d-mf-accordion-heading {
      &::before {
        display: none;
      }
    }
  }
  .one3d-mf-accordion-content {
    display: none;
  }
  .open-accordion {
    .one3d-mf-accordion-content {
      display: block;
    }
    .one3d-mf-accordion-heading {
      &::before {
        display: none;
      }
    }
  }
  .one3d-sound-off,
  .one3d-sound-on {
    top: 10px;
    left: 10px;
    position: absolute;
  }
  .one3d-features-menu-icon {
    top: 16px;
    right: 16px;
    position: absolute;
  }
  .one3d-mf-total {
    font-weight: 400;
    margin-left: 10px;
    color: var(--ion-color-gray-light-new);
  }
  .one3d-mf-current {
    margin-right: 4px;
    color: var(--ion-color-primary);
  }
  .one3d-mf-total-count {
    margin-left: 4px;
  }
  .one3d-mf-count-outside {
    position: absolute;
    top: 12px;
    right: 60px;
    .one3d-mf-count {
      margin: 0;
      padding: 4px 10px;
    }
  }
  .one3d-features-indicator-progress-bar {
    left: 0;
    width: 50%;
    z-index: 99;
    height: 4px;
    bottom: 66px;
    display: block;
    position: absolute;
    background-color: var(--ion-color-primary);
  }
  .one3d-swipe-up-for-more {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    left: 50%;
    white-space: nowrap;
    bottom: 8px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    color: var(--ion-color-secondary-contrast);
  }
  .one3d-arrow-bottom-indicator {
    // display: none;
    left: 50%;
    z-index: 2;
    bottom: 30px;
    position: absolute;
    transform: translateX(-50%);
  }
  .one3d-animated-arrow {
    width: 12px;
    height: 12px;
    border: 3px solid;
    border-color: transparent var(--ion-color-secondary-contrast)
      var(--ion-color-secondary-contrast) transparent;
    transform: rotate(45deg);
  }
  .arrow-sliding {
    position: absolute;
    -webkit-animation: slide 2s linear infinite;
    animation: slide 2s linear infinite;
  }
  .delay1 {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  .delay2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    20% {
      opacity: 1;
      transform: translateY(-10px);
    }
    80% {
      opacity: 1;
      transform: translateY(10px);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    20% {
      opacity: 1;
      transform: translateY(-10px);
    }
    80% {
      opacity: 1;
      transform: translateY(10px);
    }
    100% {
      opacity: 0;
      transform: translateY(20px);
    }
  }
  .one3d-mf-bottom-content-wrap {
    left: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    padding: 0 20px 24px 20px;
    background: rgb(13, 31, 69);
    background: linear-gradient(
      180deg,
      rgba(13, 31, 69, 0) 15%,
      rgba(0, 0, 0, 0.6) 60%
    );
    .one3d-heart-right-portion {
      right: 16px;
      bottom: 60px;
      display: flex;
      position: absolute;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      ion-text {
        display: block;
        font-size: 14px;
        font-weight: 500;
        margin: 6px 0 0 0;
        text-align: center;
        color: var(--ion-color-secondary-contrast);
      }
    }
  }
  .one3d-mf-bottom-content {
    bottom: 56px;
    position: relative;
    h3 {
      margin: 0;
      ion-text {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        color: var(--ion-color-secondary-contrast);
      }
    }
    p {
      margin: 6px 0 0;
      ion-text {
        font-size: 14px;
        font-weight: 400;
        color: var(--ion-color-secondary-contrast);
      }
    }
  }
  body {
    .one3d-lhs-panel-header {
      z-index: 2;
    }
  }
  .one3d-mf-accordion-group {
    margin: 0;
    .one3d-mf-accordion-box {
      border: none;
      border-radius: 0;
      background-color: transparent;
      color: var(--ion-color-secondary-contrast);
      ion-item::part(native) {
        text-align: right;
        background: transparent;
        border-color: transparent !important;
        color: var(--ion-color-secondary-contrast);
      }
      .one3d-mf-accordion-content-box {
        padding-top: 0;
        text-align: right;
        padding-bottom: 0;
      }
    }
  }
}
// New Mobile UI for Feature Showcase Reels Ends Here
// A/B testing ideas css
// .idea-1,
// .idea-2,
// .idea-3,
// .idea-4 {
//   .one3d-mi-ext-btn,
//   .one3d-mi-int-btn,
//   .one3d-visualizer-seating-rows {
//     display: none;
//   }
// }
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .idea-0 {
    .one3d-mi-panel {
      flex-direction: column !important;
    }
    .one3d-seating-rows-wrapper {
      margin-right: 0;
    }
    .one3d-visualizer-seating-rows {
      top: -48px !important;
    }
  }
  .idea-1 .one3d-mi-panel {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-2 .one3d-mi-panel {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-3 .one3d-mi-panel {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-4 .one3d-mi-panel {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-1 .one3d-seating-rows-wrapper {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-2 .one3d-seating-rows-wrapper {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-3 .one3d-seating-rows-wrapper {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-4 .one3d-seating-rows-wrapper {
    background: rgba(255, 255, 255, 0.7) !important;
  }
  .idea-1.configurator-is-active .one3d-color-palette {
    bottom: 36px;
    margin-left: 0;
  }
  .configurator-is-active .one3d-color-palette-active {
    bottom: 0px !important;
    margin-left: 0px !important;
  }
  .idea-0.configurator-is-active .one3d-color-palette-active {
    bottom: 44px !important;
  }
  .idea-2.configurator-is-active .one3d-color-palette,
  .idea-3.configurator-is-active .one3d-color-palette,
  .idea-4.configurator-is-active .one3d-color-palette {
    bottom: 37px !important;
    margin-left: 0px !important;
  }
  .idea-2.configurator-is-active .one3d-color-palette-active,
  .idea-3.configurator-is-active .one3d-color-palette-active,
  .idea-4.configurator-is-active .one3d-color-palette-active {
    bottom: 0px !important;
    margin-left: 0px !important;
  }
  body .one3d-lhs-config-bottom-selected.one3d-bg-dark-cc {
    background: rgba(0, 0, 0, 0.7);
    h4,
    p {
      color: #fff;
    }
  }
  .idea-0 .one3d-lhs-config-bottom-selected.one3d-bg-dark-cc {
    background: #fff;
    h4,
    p {
      color: #000;
    }
  }
  .idea-0 .one3d-lhs-bottom-price span,
  .idea-0 .one3d-lhs-config-bottom-price span {
    color: #636363;
  }
  .idea-1 div.one3d-mi-panel-element:not(:last-of-type)::after,
  .idea-2 div.one3d-mi-panel-element:not(:last-of-type)::after,
  .idea-3 div.one3d-mi-panel-element:not(:last-of-type)::after,
  .idea-4 div.one3d-mi-panel-element:not(:last-of-type)::after {
    left: auto !important;
    bottom: 15px !important;
    right: -20px !important;
    transform: rotate(90deg) !important;
  }
  .one3d-variant-name-visualizer {
    max-width: 160px;
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
//**   ====================================  Tap and drag to rotate starts here
.one3d-lhs-panel-open .one3d-tutorial-new {
  left: calc(50% + 175px);
}
.one3d-tutorial-new {
  left: 50%;
  z-index: 99;
  bottom: 30%;
  position: absolute;
  text-align: center;
  pointer-events: none;
  -webkit-transition: 0.6s all ease;
  -moz-transition: 0.6s all ease;
  -ms-transition: 0.6s all ease;
  -o-transition: 0.6s all ease;
  transition: 0.6s all ease;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
.one3d-tutorial-tap-drag-adas-screen {
  top: 30px;
  bottom: auto;
}
.one3d-tutorial-show {
  opacity: 1;
  visibility: visible;
}
.one3d-tap-drag-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--ion-color-secondary-contrast);
}
.one3d-tap-drag-strong {
  font-weight: 600;
}
.one3d-tutorial-tap-drag-circle,
.one3d-tutorial-tap-drag-circle-border {
  -webkit-animation: oscillateX 4s infinite linear;
  -moz-animation: oscillateX 4s infinite linear;
  -ms-animation: oscillateX 4s infinite linear;
  -o-animation: oscillateX 4s infinite linear;
  animation: oscillateX 4s infinite linear;
}
@-webkit-keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@-moz-keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes oscillateX {
  0% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(calc(100% - 50px));
    -moz-transform: translateX(calc(100% - 50px));
    -ms-transform: translateX(calc(100% - 50px));
    -o-transform: translateX(calc(100% - 50px));
    transform: translateX(calc(100% - 50px));
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
//**   ====================================  Tap and drag to rotate ends here
//**   ====================================  Pinch or scroll to zoom in & out starts here
.one3d-pinch-scroll-up-arrow {
  -webkit-animation: oscillateUpY 2s infinite linear;
  -moz-animation: oscillateUpY 2s infinite linear;
  -ms-animation: oscillateUpY 2s infinite linear;
  -o-animation: oscillateUpY 2s infinite linear;
  animation: oscillateUpY 2s infinite linear;
}
@-webkit-keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes oscillateUpY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(14px);
    -moz-transform: translateY(14px);
    -ms-transform: translateY(14px);
    -o-transform: translateY(14px);
    transform: translateY(14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
.one3d-pinch-scroll-down-arrow {
  -webkit-animation: oscillateDownY 2s infinite linear;
  -moz-animation: oscillateDownY 2s infinite linear;
  -ms-animation: oscillateDownY 2s infinite linear;
  -o-animation: oscillateDownY 2s infinite linear;
  animation: oscillateDownY 2s infinite linear;
}
@-webkit-keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes oscillateDownY {
  0% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(-14px);
    -moz-transform: translateY(-14px);
    -ms-transform: translateY(-14px);
    -o-transform: translateY(-14px);
    transform: translateY(-14px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
//**   ====================================  Pinch or scroll to zoom in & out ends here

