// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  // --ion-color-primary: #ffba00;
  // --ion-color-primary-rgb: 255, 186, 0;
  // --ion-color-primary-contrast: #000000;
  // --ion-color-primary-contrast-rgb: 0, 0, 0;
  // --ion-color-primary-shade: #e0a400;
  // --ion-color-primary-tint: #ffc11a;



  --ion-color-primary: #487f70;
	--ion-color-primary-rgb: 72,127,112;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #3f7063;
	--ion-color-primary-tint: #5a8c7e;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0, 0, 0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /*** gray-dark ***/
  --ion-color-gray-dark: #3e3e3e;
  --ion-color-gray-dark-rgb: 62, 62, 62;
  --ion-color-gray-dark-contrast: #ffffff;
  --ion-color-gray-dark-contrast-rgb: 255, 255, 255;
  --ion-color-gray-dark-shade: #373737;
  --ion-color-gray-dark-tint: #515151;

  /*** gray-light ***/
  --ion-color-gray-light: #bfbfbf;
  --ion-color-gray-light-rgb: 191, 191, 191;
  --ion-color-gray-light-contrast: #000000;
  --ion-color-gray-light-contrast-rgb: 0, 0, 0;
  --ion-color-gray-light-shade: #a8a8a8;
  --ion-color-gray-light-tint: #c5c5c5;

  /*** gray-light-new ***/
  --ion-color-gray-light-new: #636363;
  --ion-color-gray-light-new-rgb: 99, 99, 99;
  --ion-color-gray-light-new-contrast: #ffffff;
  --ion-color-gray-light-new-contrast-rgb: 255, 255, 255;
  --ion-color-gray-light-new-shade: #575757;
  --ion-color-gray-light-new-tint: #737373;

  /*** green-new-update ***/
  --ion-color-487f70: #487F70;
	--ion-color-487f70-rgb: 72,127,112;
	--ion-color-487f70-contrast: #ffffff;
	--ion-color-487f70-contrast-rgb: 255,255,255;
	--ion-color-487f70-shade: #3f7063;
	--ion-color-487f70-tint: #5a8c7e;
}