@font-face {
  font-family: "Heebo";
  src: url("./Heebo-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-ExtraLight.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Heebo";
  src: url("./Heebo-Thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');